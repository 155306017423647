<template>
  <div class="box-list-account">
    <div class="list-title">
      <b-button variant="outline-success" class="btn-egg" v-b-modal.create-demo>
        Create Account
      </b-button>
    </div>
    <div class="list-account">
      <div class="box-account" v-for="account in Demo" :key="account.subaccount_demo">
        <div class="account-box">
          <div class="account-box-header">
            <b-row class="p-0 m-0">
              <b-col cols="9">
                <div class="name-account d-flex">
                  <span> {{ account.subaccount_demo }}</span>
                </div>
              </b-col>
              <b-col cols="3" class="d-flex justify-content-end align-items-center">
                <b-button class="setting-account" @click="settingSub(account)">
                  <i class="fas fa-cog"></i>
                </b-button>
              </b-col>
              <b-col cols="12" class="box-detail-header">
                <div class="box-balance">
                  <div class="value">
                    {{ TruncateToDecimals(parseFloat(account.balance), '', 2) }}
                  </div>
                  <div class="title">balance</div>
                </div>
                <div class="box-balance type-account">
                  <div class="value">{{ account.subaccount_type }}</div>
                  <div class="title">Type Account</div>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="account-box-body">
            <div class="detail-account account">
              <div class="value">{{ account.subaccount_id }}</div>
              <div class="title">Account</div>
            </div>
            <div class="detail-account platform">
              <div class="value">{{ account.subaccount_platform }}</div>
              <div class="title">platform</div>
            </div>
            <div class="detail-account leverage">
              <div class="value">{{ account.subaccount_lever }}:1</div>
              <div class="title">leverage</div>
            </div>
          </div>
          <div class="account-box-footer">
            <b-button
              class="btn-egg"
              variant="success"
              @click="showDeposit(account)"
              :disabled="account.balance > 500"
            >
              Fund Now
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="create-demo"
      :hide-header="true"
      :hide-footer="true"
      modal-class="d-flex justify-content-center align-items-center"
      @hide="resetCreate()"
    >
      <b-form @submit.prevent="onCreateDemo()">
        <div class="form-group form-title">
          <h4>Request an Individual Demo Account</h4>
        </div>
        <div class="form-group">
          <label for="platform-type">Platform Type</label>
          <b-form-select
            v-model.trim="create.sub_platform"
            required
            id="platform-type"
            :options="platform"
          ></b-form-select>
        </div>
        <div class="form-group">
          <label for="sub_type-type">Account Type</label>
          <b-form-select
            v-model.trim="create.sub_type"
            required
            id="sub_type-type"
            :options="account_type"
          ></b-form-select>
        </div>
        <div class="form-group">
          <label for="Leverage">Leverage</label>
          <b-form-select
            v-model.trim="create.sub_lever"
            required
            id="Leverage"
            :options="levarage"
          ></b-form-select>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <b-form-input
            v-model.trim="create.sub_password"
            required
            type="password"
            id="password"
            placeholder="Enter your password"
          ></b-form-input>
        </div>
        <div class="form-group mt-5 d-flex justify-content-center align-items-center mb-0">
          <b-button variant="danger" class="mx-2" @click="$bvModal.hide('create-demo')">
            Cancel
          </b-button>
          <b-button variant="outline-success" class="mx-2 btn-egg" type="submit">Create</b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="setting-demo"
      :hide-header="true"
      :hide-footer="true"
      modal-class="d-flex justify-content-center align-items-center"
      @hide="resetSetting()"
    >
      <b-form @submit.prevent="onUpdateDemo()">
        <div class="form-group form-title">
          <h4>Setting Demo Account</h4>
        </div>
        <div class="form-group">
          <label for="Leverage">Leverage ( Option Change )</label>
          <b-form-select
            v-model.trim="setting.lever"
            required
            id="Leverage"
            :options="levarage"
          ></b-form-select>
        </div>
        <div class="form-group">
          <label for="password">Password ( Option Change )</label>
          <b-form-input
            v-model.trim="setting.password"
            type="password"
            id="password"
            placeholder="Enter your password"
          ></b-form-input>
        </div>
        <div class="form-group mt-5 d-flex justify-content-center align-items-center mb-0">
          <b-button variant="danger" class="mx-2" @click="$bvModal.hide('setting-demo')">
            Cancel
          </b-button>
          <b-button variant="outline-success" class="mx-2 btn-egg" type="submit">Update</b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="deposit-demo"
      :hide-header="true"
      :hide-footer="true"
      modal-class="d-sm-flex justify-content-sm-center align-items-sm-center"
    >
      <div class="deposit-demo-box">
        <div class="title">Deposit into demo account</div>
        <div class="sub-title">
          To add funds to your demo account please enter the amount and click "Fund Now"
        </div>
        <div class="form-group">
          <label for="CoinWithdraw">Amount</label>
          <b-form-select
            v-model.trim="deposit.amount"
            required
            id="CoinWithdraw"
            :options="AmountList"
            value-field="value"
            text-field="text"
          ></b-form-select>
        </div>
        <div class="group-button d-flex justify-content-end align-items-center">
          <button
            type="button"
            class="btn mx-2 btn-secondary"
            @click="$bvModal.hide('deposit-demo')"
          >
            Close
          </button>
          <button type="button" class="btn btn-egg mx-2 btn-success" @click="onDeposit()">
            Fund Now
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      deposit: {
        subAccountID: null,
        amount: 5000,
        token: null,
      },
      create: {
        sub_currency: 'ETH',
        sub_password: '',
        sub_platform: 'CTrade',
        sub_demo: 'Demo',
        sub_type: 'Standar',
        sub_lever: 1,
        token: null,
      },
      setting: {
        lever: null,
        password: null,
        subAccountID: null,
      },
      AmountList: [
        { value: 5000, text: '5000' },
        { value: 10000, text: '10000' },
      ],
      onDepositDemo: true,
    };
  },
  computed: {
    ...mapGetters({
      Demo: 'account/Demo',
    }),
    levarage() {
      return [
        { value: 1, text: '1:1' },
        { value: 25, text: '1:25' },
        { value: 30, text: '1:30' },
        { value: 50, text: '1:50' },
        { value: 100, text: '1:100' },
        { value: 200, text: '1:200' },
        { value: 300, text: '1:300' },
        { value: 400, text: '1:400' },
        { value: 500, text: '1:500' },
      ];
    },
    platform() {
      return [
        // { value: 'Rebate', text: 'Rebate' },
        { value: 'CTrade', text: 'CTrade ' },
      ];
    },
    account_type() {
      return [
        { value: 'Standar', text: 'Standar ' },
        { value: 'ECN', text: 'Electronic Communication Network(ECN)' },
      ];
    },
  },
  methods: {
    async onCreateDemo() {
      const token = await this.genarateCaptChaV3('create_account');
      if (!token) {
        this.$toastr.e('Too Fast! Please Wating Recaptcha.', 'Withdraw Failed');
      }
      this.create.token = token;
      this.$store.dispatch('account/req_postRegisterSub', this.create);
    },
    showDeposit(user) {
      this.deposit.subAccountID = user.subaccount_id;
      this.$bvModal.show('deposit-demo');
    },
    onUpdateDemo() {
      const account = {};
      if (this.setting.lever) {
        account.lever = this.setting.lever;
      }
      if (this.setting.password) {
        account.password = this.setting.password;
      }
      account.subAccountID = this.setting.subAccountID;
      this.$store.dispatch('account/req_postSettingSub', account);
    },
    settingSub(sub) {
      this.setting = {
        lever: sub.subaccount_lever,
        password: null,
        subAccountID: sub.subaccount_id,
      };
      this.$bvModal.show('setting-demo');
    },
    async onDeposit() {
      if (!this.onDepositDemo) return;
      this.onDepositDemo = false;
      const token = await this.genarateCaptChaV3('wallet');
      if (!token) {
        this.$toastr.e('Too Fast! Please Wating Recaptcha.', 'Withdraw Failed');
      }
      this.deposit.token = token;
      this.$store.dispatch('wallet/req_postDepositDemo', this.deposit);
      this.onDepositDemo = true;
    },
    resetCreate() {
      this.create = {
        sub_currency: 'ETH',
        sub_password: '',
        sub_platform: 'CTrade',
        sub_demo: 'Demo',
        sub_type: 'Standar',
        sub_lever: 1,
        token: null,
      };
    },
    resetSetting() {
      this.setting = {
        lever: null,
        password: null,
        subAccountID: null,
      };
    },
  },
  mounted() {},
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'account/REGISTER_SUB_SUCCESS':
          this.$bvModal.hide('create-demo');
          this.create = {
            sub_currency: 'ETH',
            sub_password: '',
            sub_platform: 'CTrade',
            sub_demo: 'Demo',
            sub_type: 'Standar',
            sub_lever: 1,
            token: null,
          };
          break;
        case 'wallet/DEPOSIT_SUCCESS':
          this.$bvModal.hide('deposit-demo');
          break;
        case 'account/SETTING_SUB_SUCCESS':
          this.$bvModal.hide('setting-demo');
          this.setting = {
            lever: null,
            password: null,
            subAccountID: null,
          };
          break;
        default:
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
#deposit-demo,
#deposit,
#withdraw,
#transfer {
  .modal-dialog {
    width: 100%;
  }
}
#deposit-demo {
  .modal-body {
    .title {
      padding: 0.25rem;
      font-size: clamp(18px, 3vw, 22px);
      border-bottom: 2px solid rgba(108, 117, 125, 0.4117647058823529);
      margin-bottom: 0.5rem;
    }
    .sub-title,
    .title {
      color: #2f394e;
      font-weight: 700;
      text-transform: capitalize;
      text-align: center;
    }
    .sub-title {
      margin-bottom: 15px;
    }
    .form-group {
      padding: 0 0.75rem 0.75rem;
      margin-bottom: 0;
      label {
        font-weight: 600;
        color: #6c757d;
        font-size: 17px;
        margin-bottom: 0;
      }
      select {
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid #6c757d;
        height: auto;
        font-size: clamp(17px, 3vw, 20px);
        font-weight: 600;
      }
    }
  }
}
#create-demo {
  .form-title {
    border-bottom: 3px solid #00000333;
    h4 {
      color: #2f394e;
      font-weight: 600;
      font-size: 18px;
    }
  }
  .form-group {
    label {
      font-weight: 600;
      color: #6c757d;
      font-size: 16px;
      margin-bottom: 0px;
    }
    select,
    input {
      border: 0;
      border-radius: 0;
      border-bottom: 2px solid #6c757d;
      height: 40px;
      font-size: clamp(15px, 1vw, 16.2px);
      font-weight: 600;
      &:focus {
        box-shadow: none;
        border-bottom: 2px solid #097501;
      }
      &::placeholder {
        font-weight: 500;
        font-size: clamp(13px, 1vw, 15px);
      }
    }
  }
}
.box-list-account {
  width: 100%;
  margin-top: 50px;
  min-height: 200px;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #00000333;
  position: relative;
  margin-bottom: 1.25rem;
  .list-title {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 0;
    text-align: center;
    position: relative;
    button {
      font-weight: 600;
      position: absolute;
      top: -50%;
      width: 100%;
      max-width: 210px;
    }
  }
  .list-account {
    padding: 0 1.25rem 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .box-account {
      flex: 0 0 25%;
      max-width: 310px;
      .account-box {
        box-shadow: 0px 0px 10px 2px #00000038;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        margin: 0 auto 15px;
        position: relative;
        width: calc(100% - 20px);
        flex-direction: column;
        .account-box-header {
          background-image: url(~@/assets/images/background/overlay.png);
          padding: 15px 0;
          border-radius: 10px 10px 0 0;
          width: 100%;
          .name-account {
            color: #fff;
            font-weight: 600;
            font-size: clamp(16px, 2vw, 20px);
            text-transform: uppercase;
          }
          .setting-account {
            background: transparent;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #fff;
          }
          .box-detail-header {
            display: flex;
            justify-content: space-between;
            .box-balance {
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              margin-top: 10px;
              .value {
                font-weight: 600;
                color: #ffff;
                font-size: 20px;
                line-height: 120%;
                text-align: center;
              }
              .title {
                font-weight: 400;
                color: #ffff;
                letter-spacing: 1px;
                opacity: 0.9;
                text-transform: capitalize;
                font-size: clamp(14px, 2vw, 17px);
                line-height: 100%;
              }
            }
            .type-account {
              width: max-content;
              .value {
                font-size: 17px;
              }
              .title {
                font-size: clamp(13px, 2vw, 15px);
              }
            }
          }
        }
        .account-box-body {
          width: 100%;
          padding: 15px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          align-content: space-around;
          align-items: stretch;
          height: 100%;
          .detail-account {
            min-width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: 10px auto;
            .title {
              font-weight: 600;
              color: #2f394e;
              opacity: 0.9;
              text-align: center;
              text-transform: capitalize;
              font-size: clamp(11px, 2vw, 10px);
            }
            .value {
              font-weight: 700;
              font-size: 20px;
              color: #2f394e;
              text-align: center;
              font-size: clamp(16px, 2vw, 18px);
            }
            &.account {
              width: 100%;
            }
            &.platform {
            }
            &.leverage {
            }
          }
        }
        .account-box-footer {
          width: 100%;
          border-top: 2px solid rgba(0, 0, 0, 0.1);
          padding: 15px 5px;
          border-radius: 0 0 15px 15px;
          display: flex;
          justify-content: space-around;
          button {
            min-width: unset;
            width: auto;
            padding: 5px;
            flex: 1;
            max-width: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            height: 40px;
            margin: 3px;
          }
        }
      }
    }
    @media (max-width: 1600px) {
      .box-account {
        flex: 0 0 33.3%;
        max-width: 33.3%;
      }
    }
    @media (max-width: 991px) {
      .box-account {
        flex: 0 0 50%;
        max-width: 50%;
        .account-box {
          width: calc(100% - 10px);
          max-width: 320px;
        }
      }
    }
    @media (max-width: 767px) {
      .box-account {
        flex: 0 0 100%;
        max-width: 100%;
        .account-box {
          width: 100%;
          max-width: 320px;
        }
      }
    }
  }
}
</style>
